.header {
    padding: 10px;
    text-align: center;

    a {
        display: inline-block;
        margin-right: 10px;
        padding: 5px 10px;
        svg {
            width: 18px;
            margin-right: 5px;
        }
    }
}